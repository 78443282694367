import gju from "geojson-utils";

// Calculate the route length
export function CalcRouteLength(planedRoute) {

  var routeLen = 0

  for (let i = 0; i < planedRoute.length - 1; i++) {
    routeLen += calcRoutePointsDistance(planedRoute[i], planedRoute[i + 1])
  }
  return routeLen;
}

export function calcRoutePointsDistance(point1, point2){
  const lat1 = point1.latlng.lat;
  const lng1 = point1.latlng.lng;
  const lat2 = point2.latlng.lat;
  const lng2 = point2.latlng.lng;
  return (
    gju.pointDistance({type: 'Point', coordinates:[lng1, lat1]},{type: 'Point', coordinates:[lng2, lat2]}) / 1852
  )
}

export function calcDbPointsDistance(point1, point2){
  const lat1 = point1.latlng[0];
  const lng1 = point1.latlng[1];
  const lat2 = point2.latlng[0];
  const lng2 = point2.latlng[1];
  return (
    gju.pointDistance({type: 'Point', coordinates:[lng1, lat1]},{type: 'Point', coordinates:[lng2, lat2]}) / 1852
  )
}

/********************************************************* */

export function isPointInCircle(center, radius, point){

  return (
    gju.pointDistance({type: 'Point', coordinates:[center[1], center[0]]},{type: 'Point', coordinates:[point[1], point[0]]}) <= radius);
}

/********************************************************* */

export function isCircleContainsBubble(center, radius,bubble){


  const polygonData = {"type":"Polygon", "coordinates": [bubble.coords]}

  const centerData = {type: 'Point', coordinates: center}

  if (!gju.pointInPolygon(centerData, polygonData)) {
    return false;
  }

  // Check if any point of the polygon is outside the circle
  for (let i = 0; i < bubble.coords.length; i++) {

    if (!isPointInCircle(center, radius, bubble.coords[i] )){
      return false
    }
  }

  return true;
}

/********************************************************* */

export function polygonContainsBubble(outerPolygon, bubble) {

  const innerPolygon = bubble.coords;

  const OuterPolygonData = {"type":"Polygon", "coordinates": [outerPolygon]}

  for (let i = 0; i < innerPolygon.length; i++) {

    const pointData = {type: 'Point', coordinates: innerPolygon[i]}

    if (!gju.pointInPolygon(pointData, OuterPolygonData)) {
      return false;
    }
  }

  for (let i = 0; i < outerPolygon.length; i++) {
    const out1 = outerPolygon[i];
    const out2 = outerPolygon[(i + 1) % outerPolygon.length];

    for (let j = 0; j < innerPolygon.length; j++) {
      const in1 = innerPolygon[j];
      const in2 = innerPolygon[(j + 1) % innerPolygon.length];


      if (lineSegmentsIntersect(out1[0], out1[1], out2[0], out2[1], in1[0], in1[1], in2[0], in2[1] )) {
        return false;
      }
    }
  }

  return true;
}

/*************************************************************************************/

function lineSegmentsIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  function ccw(A,B,C) {
      return (C[1]-A[1])*(B[0]-A[0]) > (B[1]-A[1])*(C[0]-A[0]);
  }
  return ccw([x1,y1],[x3,y3],[x4,y4]) !== ccw([x2,y2],[x3,y3],[x4,y4]) && ccw([x1,y1],[x2,y2],[x3,y3]) !== ccw([x1,y1],[x2,y2],[x4,y4]);
}

/*************************************************************************************/

export function orderPolygon(points){

  // Find min max to get center
  // Sort from top to bottom
  points.sort((a,b)=>a[1] - b[1]);

  console.log('points:',points);
  
  // Get center y
  const cy = (points[0][1] + points[points.length -1][1]) / 2;
  
  // Sort from right to left
  points.sort((a,b)=>b[0] - a[0]);
  
  // Get center x
  const cx = (points[0][0] + points[points.length -1][0]) / 2;
  
  // Center point
  const center = [cx,cy];
  
  // Pre calculate the angles as it will be slow in the sort
  // As the points are sorted from right to left the first point
  // is the rightmost
  
  // Starting angle used to reference other angles
  var startAng;
  points.forEach(point => {
      var ang = Math.atan2(point[1] - center[1],point[0] - center[0]);
      if(!startAng){ startAng = ang }
      else {
           if(ang < startAng){  // ensure that all points are clockwise of the start point
               ang += Math.PI * 2;
           }
      }
      point.angle = ang; // add the angle to the point
   });
    
   // Sort clockwise;
   points.sort((a,b)=> a.angle - b.angle);

   points = points.map((p) => {return [p[0],p[1]]}); 
 
  // Return the sorted points
    return points;
}

/*************************************************************************************/

export function parseDMS(dms){

  var parts = dms.split(/[NE]/);

  if(parts.length ===3 && parts[1].length && parts[2].length){

    var latD = parts[1].slice(0,2).length ? parseInt(parts[1].slice(0,2)): 0
    var latM = parts[1].slice(2,4).length ? parseInt(parts[1].slice(2,4)): 0
    var latS = parts[1].slice(4).length ?   parseInt(parts[1].slice(4)): 0

    var lngD = parts[2].slice(0,3).length ? parseInt(parts[2].slice(0,3)) : 0
    var lngM = parts[2].slice(3,5).length ? parseInt(parts[2].slice(3,5)) : 0
    var lngS = parts[2].slice(5).length ?   parseInt(parts[2].slice(5)) : 0

    var lat =  latD + latM/60 + latS/(60*60);
    var lng =  lngD + lngM/60 + lngS/(60*60);

    if(lat && lng){
      return {
        lat: +parseFloat(lat.toFixed(4)),
        lng: +parseFloat(lng.toFixed(4))
      }
    }
  }

  return null;
}

