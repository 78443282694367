
import {LoadReportPoints, LoadBubblesPoints,  calcDbPointsDistance, getTimeCategoryHour} from ".";
import {rulesMod} from "../data"
import {isProduction} from "../utils"


/***********************************************/
// export function getRuleInfo(rule){
//     var ruleString = ""


//     ident: `${notam.series}${getDigits(notam.number,4)}/${notam.year}`,
//     icao: notam.itema[0],
//     abbrContent: notam.iteme,
//     // fullContent: notam.content,
//     type: 'AUTO',
//     date: new Date(),
//     lowerAlt: notam.lower * 100,
//     upperAlt: notam.upper * 100,
//     // lat: notam.lat,
//     // lng: notam.lon,
//     // startValidity: notam.startvalidity,
//     // endValidity: notam.endvalidity,
//     startTime: notam.startTime,
//     endTime: notam.endTime,
//     schedule: parseSchedule(notam.itemd),

// }

/***********************************************/
export function filterBubbles(bubbles,rules){

   var filteredBubbles = []; 

   if(rules){

      const ruleExist = rules.findIndex((rule) => (rule.airwayType === "LSA-BUBBLES"));
       
      // If there is a relevant rule than clone the array
      if(ruleExist > -1){
       filteredBubbles = cloneObject(bubbles) 
      }

      for (const rule of rules){
       if(rule.airwayType === "LSA-BUBBLES"){
           for (var bubble of filteredBubbles){

                // console.log('rule.bubbles', rule.bubbles);
               if(rule.bubbles && rule.bubbles.includes(bubble.icao) && rule.airwayType === "LSA-BUBBLES"){
                   if(rule.status === "CLOSED" ){
                       bubble.closed = true;
                       bubble.closeDescription = bubble.name + " סגורה " + rule.timeReason ;
                       if(rule.ident)
                           bubble.closeDescription += ` ראה נוטאם מספר ${rule.ident}`
                       if (!isProduction()) {bubble.originalNotam = rule};

                   }
               }
           }
       }
     }
   }

   return (filteredBubbles.length ? filteredBubbles : bubbles)

}


/***********************************************/

export function filterAirstrips (airstrips, rules){

   var filteredAirstrips = []; 

   if(rules){

    const ruleExist = rules.findIndex((rule) => (rule.airwayType === "AIRSTRIP"));
     
    // If there is a relevant rule than clone the array
    if(ruleExist > -1){
       filteredAirstrips = cloneObject(airstrips) 
    }
           
    for (const rule of rules){

         if(rule.airwayType === "AIRSTRIP"){
             const ind = filteredAirstrips.findIndex((o) => o.icao === rule.icao);

             if(ind > -1 ){
                 if( rule.status === "CLOSED"){
                   filteredAirstrips[ind].closed = true
                   filteredAirstrips[ind].closeDescription =  filteredAirstrips[ind].name + " סגור " + rule.timeReason ;
                   if(rule.ident){
                     filteredAirstrips[ind].closeDescription +=  ` ראה נוטאם מספר ${rule.ident}`
                   }
                   
                   if (!isProduction()) {filteredAirstrips[ind].originalNotam = rule};

                   filteredAirstrips[ind].routePoints = [];
                 }
             }
         }
     }
   }

   return (filteredAirstrips.length ? filteredAirstrips : airstrips)
}

/***********************************************/


export function filterNavPointsByRules(navPoints,rules,mapUsed, timeCategory){

 var filteredPoints = []

 if(rules){

     const ruleExist = rules.findIndex((rule) => (rule.airwayType === "LSA-ROUTE" && mapUsed === "lsa")   || 
                                                 (rule.airwayType === "CVFR-ROUTE" && mapUsed === "cvfr") ||
                                                 (rule.airwayType === "POLYGON") ||
                                                 (rule.airwayType === "CIRCLE"));
     
     // If there is a relevant rule then clone the array
     if(ruleExist > -1){
         filteredPoints =  cloneObject(navPoints) 
     }

     for (const rule of rules){
        if((rule.airwayType === "LSA-ROUTE" && mapUsed === "lsa") || (rule.airwayType === "CVFR-ROUTE" && mapUsed === "cvfr")){

            if(rule.routes && rule.routes.length){
                for(const route of rule.routes){
                    if( route && route.length ){
                        if(rule.status === "CLOSED" ) {
                            for(const routePoint of route){
                                // find the relevant point
                                const ind = filteredPoints.findIndex((o) => o.icao === routePoint);
                                if (ind > -1) {
                                    // remove all routes which are in the list
                                    filteredPoints[ind].route = filteredPoints[ind].route.filter((point) => {return (!route.includes(point.icao))})
                                }
                            }
                            handleRuleMods(filteredPoints, route)
                        } else if(rule.status === "OPENED"){
                            setOpenedRoute(rule, filteredPoints, mapUsed, timeCategory);
                        }
                    }
                }
            }
        } else if (rule.airwayType === "POLYGON" || rule.airwayType === "CIRCLE") {

            const pointsInArea = mapUsed === "lsa" ? rule.lsaPoints : rule.cvfrPoints;

            if(pointsInArea.length){

                filteredPoints = filteredPoints.map((point) => {
                    if(pointsInArea.includes(point.icao)){
                        point.route = point.route.map((dest) => {
                            if(dest.alt > rule.lowerAlt && dest.alt <= rule.upperAlt){
                                dest.active = false;
                            }
                            return dest
                        })
                    } else {
                        point.route = point.route.map((dest) => {
                            if(pointsInArea.includes(dest.icao)){
                                if(dest.alt > rule.lowerAlt && dest.alt <= rule.upperAlt){
                                    dest.active = false;
                                }
                            }
                            return dest
                        })
                    }
                    return point;
                })
            }
        }
     }
 }
 return (filteredPoints.length ? filteredPoints : navPoints)
}

/***********************************************/
function handleRuleMods(filteredPoints, route){

    for (const ruleMod of rulesMod){
        // check if the rule route matches the rule mod
        if(route.includes(ruleMod.ifClosed[0]) && route.includes(ruleMod.ifClosed[1])){
            for (const alsoClose of ruleMod.alsoClose ){
                var fInd = filteredPoints.findIndex((o) => o.icao === alsoClose[0]);
                var tInd = filteredPoints.findIndex((o) => o.icao === alsoClose[1]);

                if(fInd > -1){
                    let rInd = filteredPoints[fInd].route.findIndex((o) => o.icao === alsoClose[1]);
                    if(rInd > -1){
                        filteredPoints[fInd].route[rInd].active = false;
                    }
                }

                if(tInd > -1){
                    let rInd = filteredPoints[tInd].route.findIndex((o) => o.icao === alsoClose[0]);
                    if(rInd > -1){
                        filteredPoints[tInd].route[rInd].active = false;
                    }
                }
            }
        }
    }
}

/***********************************************/

function setOpenedRoute(rule, navPoints, mapUsed, timeCategory){

 // Load all the points in case we have to add some.
 var allPoints = []
 if(mapUsed === "lsa"){
     allPoints = [...LoadReportPoints(mapUsed,"AllOpen"),...LoadBubblesPoints(mapUsed,"AllOpen")];
 } else {
     allPoints = navPoints
 }

 if(rule.routes && rule.routes.length){
    for(const route of rule.routes){

        for (let i = 0; i < route.length; i++ ){

            const routeIcao = route[i]
       
            // try first at current points
            let rInd = navPoints.findIndex((o) => o.icao === routeIcao);
       
            if(rInd > -1){
       
                if(i < route.length - 1){
                    enableRoutePoint(navPoints[rInd], route[i + 1] )
                }
                if(i > 0 ){
                    enableRoutePoint(navPoints[rInd], route[i - 1] )
                }
       
            } else {
       
                // Then in all Points
                let rInd = allPoints.findIndex((o) => o.icao === routeIcao);
       
                if (rInd > -1) {
       
                    var pointToAdd = cloneObject(allPoints[rInd]) 
                    if(i < rule.route.length - 1){
                        enableRoutePoint(pointToAdd, route[i + 1] )
                    }
                    if(i > 0 ){
                        enableRoutePoint(pointToAdd, route[i - 1] )
                    }
       
                    navPoints.push(pointToAdd)
                }
            }
        }
    }
 }


/***********************************************/

 function enableRoutePoint(routePoint, ruleIcao){

     let pInd =  routePoint.route.findIndex((o) => o.icao === ruleIcao);

     if(pInd > -1){
         routePoint.route[pInd].active = true;
         routePoint.route[pInd].time = getTimeCategoryHour(timeCategory);
     } else{
         // in case the next point is not in the route list add it
         let rInd =allPoints.findIndex((o) => o.icao === ruleIcao);
         if(rInd > -1){
             let distance = Math.round( calcDbPointsDistance(routePoint, allPoints[rInd] ) * 10)
             routePoint.route.push({
                 icao: ruleIcao,
                 distance: distance,
                 alt: 0,
                 time:  getTimeCategoryHour(timeCategory),
                 active: true
             })
         }
     }
 }    
}

/***********************************************/

function cloneObject (obj){

 return  (typeof structuredClone === 'function') ? structuredClone(obj) : JSON.parse(JSON.stringify(obj)) 

}

/***********************************************/
