import { isAirport, getDigits} from ".";
import {flightTimeValues} from "../data";

/***********************************************/

export function CreateEmailContent(fplContent,picInfo, departureTime,route,mapUsed,timeOverlap)
    {
        const {callSign, endurance, aircraftSpeed, flightTime, aircraftType, pob} = fplContent;
        const {picName, licenseNum, phoneNumber, emailAddress} = picInfo;

        var departureHour = (getDigits(departureTime.getHours(),2)+ ':' + getDigits(departureTime.getMinutes(),2)).toString();
        var departureDay = (getDigits(departureTime.getDate(),2)).toString() +':' + getDigits(departureTime.getMonth() + 1,2) + ':' + getDigits(departureTime.getFullYear(),2) ;

        var departureHourUTC = (getDigits(departureTime.getUTCHours(),2) + getDigits(departureTime.getUTCMinutes(),2)).toString();
        var departureDayUTC = (getDigits(departureTime.getUTCFullYear(),2)  + getDigits(departureTime.getUTCMonth() + 1,2) + getDigits(departureTime.getUTCDate(),2)).toString() ;

        // Landing time for the hebrew text
        const landingTime =  new Date(departureTime.getTime());
        const index = flightTimeValues.findIndex((a) => a[0] === fplContent.flightTime);
        if (index > -1) {
            landingTime.setMinutes(landingTime.getMinutes() + flightTimeValues[index][2])
        } else {
            landingTime.setHours(landingTime.getHours() + parseInt(flightTime) / 100)
        }
        var LandingHour = (getDigits(landingTime.getHours(),2)+ ':' + getDigits(landingTime.getMinutes(),2)).toString();

        // Add zzzz in case we don't depart or land in an airport
        var inxEnd = route.length
        var departingAirfield = "ZZZZ"
        var lastLandingAirfield= "ZZZZ"
        var departingPoint = null
        var landingPoint= null

        if(isAirport(route[0].icao)){
            departingAirfield = route[0].icao;
        }
   
        if(isAirport(route[inxEnd - 1].icao)){
            lastLandingAirfield = route[inxEnd - 1].icao;
        }


        const PointsToReplace = [
            ["BNTOF1", "BNTOF"],
            ["BNAMM1", "BNAMM"],
            ["BHROD1", "BHROD"],
            ["BHROD2", "BHROD"],
            ["BMGAW1", "BMGAW"],
            ["BSARN1", "BSARN"],
        ]

        PointsToReplace.forEach(ptr => {
            route.forEach(point => {
                if(point.icao === ptr[0]){
                    point.icao = ptr[1]
                }
            });
        })

  
        // Points that do not exist in the EAD DB
        const PointsToRemove = [
            // "HTIVA",
            // "TALEL",
            // "ZGLNI",
            // "YASIF",
        ]

        // Remove airports and illegal icao points
        const correctedRoute = route.filter(rPoint => {
            return (PointsToRemove.findIndex(point => point === rPoint.icao) === -1 && rPoint.icao.length === 5)
        })

        // If the departing point is not an airport, we have to add a remark on line-18
        if(departingAirfield === "ZZZZ"){
            departingPoint = correctedRoute[0]
            correctedRoute.shift()
        }

        // If the landing point is not an airport, we have to add a remark on line-18
        if(lastLandingAirfield === "ZZZZ"){
            landingPoint = correctedRoute.pop();
        }

        var depDest = ""
        if(departingPoint){
            depDest += " DEP/" + departingPoint.icao
        }

        if(landingPoint){
            depDest += " DEST/" + landingPoint.icao
        }
        
        // Create the route - Line-15
        var icaoRoute = ""
        for (let i = 0; i < correctedRoute.length; i++) {
            icaoRoute += " " + correctedRoute[i].icao
        }

        var hebrewRoute = "נתיב: "

        for (let i = 1; i < route.length - 1; i++) {
            hebrewRoute += route[i].name.replace('""','') 
            if(i !== route.length - 2){
                hebrewRoute += ' - ' 
            }
        }

        const correctedCallSign = ((((callSign.length < 4) ? "4X":"") + callSign).replace(/\s|-/g, '')).toUpperCase() ;

        const phone = phoneNumber.replace(/[^\d+]/g, '')

        const mapText = mapUsed === 'lsa'? 'התעופה הספורטיבית' : 'התובלה הנמוכים'

        const aircraft = aircraftType

        var icaoFpl = []
        icaoFpl.push("(FPL-" + correctedCallSign + "-VG");
        icaoFpl.push( "-" + aircraft + "/L-S/C");
        icaoFpl.push( "-" + departingAirfield + departureHourUTC);
        icaoFpl.push( "-N" + aircraftSpeed + "VFR" + icaoRoute);
        icaoFpl.push( "-" + lastLandingAirfield + flightTime);
        icaoFpl.push( "-DOF/" + departureDayUTC + " RMK/PIC " + picName.toUpperCase() + " LICENSE " +  licenseNum + " CELL " + phone + depDest);
        icaoFpl.push( "-E/" + endurance +  " P/" + pob +  " )");

        var hebFpl = []
        hebFpl.push( 'תכנית טיסה בנתיבי ' + mapText);
        hebFpl.push( 'המראה מ' + route[0].name + " ב " + departureHour + " זמן מקומי");
        hebFpl.push( hebrewRoute );
        hebFpl.push( 'נחיתה ב' + route[route.length - 1].name + " ב " + LandingHour);

        const emailContent = {

            subject:  "FPL - " +  departureDay + " - " + departureHour + " - " + correctedCallSign,
            reply_to: emailAddress,
            name: picName,
            phone_num: phone,
            icaoFpl: icaoFpl,
            hebFpl: hebFpl,
            timeOverlap: timeOverlap,
        }

        return emailContent;
    }

/********************************************************************/        

    export function sendMailtoMessage(emailContent){

        const {emailSubject, emailText} = emailContent

        // var emailTo = ""
        // var cc = ""

        const emailTo = "ais@iaa.gov.il";
        const cc = "&cc=davidi@hollander.fm";
        
        const mailtoMessage = 'mailto:' + emailTo + '?subject=' + emailSubject + cc + '&body=' + encodeURIComponent(emailText)
        window.open(mailtoMessage, '_self');

    }
  
    export default CreateEmailContent;
