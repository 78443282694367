import {MenuItem, InputLabel, FormControl, Select as MuiSelect} from '@mui/material'
import Select from 'react-select';

import {isMobile, browserName} from 'react-device-detect';

export function FplSelector(props){

        const {label, value, name, setFunc, menuItems } = props

        const handleChange = (event) => {
            setFunc(event.target);
        }

        const handleAndroidChromeChange = (event) => {

            // console.log('event', event);
            setFunc({
                name : name, 
                value: event.value
            });
        }

        if (isMobile && browserName === "Chrome") {
            
            const chromeOptions = Array.from(menuItems, function (item) {
                return { key: item[0], value: item[0] , label: item[1]}
            });

            return(
                <span>
                    <p></p>
                    {label}
                    <Select
                        defaultValue={chromeOptions.find((i) => i.value === value)}
                        onChange={handleAndroidChromeChange}
                        options= {chromeOptions}
                        name={name}
                        isSearchable = {false}
                    >
                    </Select>
                </span>
            )

        } else {
            return(
                <FormControl variant="standard" sx={{ mt: 2, mr:2, minWidth: 118 }}>
                    <InputLabel >{label}</InputLabel>
                    <MuiSelect
                        value={value}
                        label={label}
                        name={name}
                        onChange={handleChange}
                    >
                        {menuItems.map((item) => <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>)}
                    </MuiSelect>
                </FormControl>
            )
        }

    }

    export default FplSelector;

