import { memo} from "react";
import { Circle, Pane } from 'react-leaflet';
import {LoadReportPoints} from "../utils";

export function ReportPoints(props) {

    console.log('Loading report points');

    const {addToRoute, mapUsed, timeCategory, openAirways} = props

    const pathOptions = { opacity: 0 , fillOpacity: 0}

    const reportPoints = LoadReportPoints(mapUsed,timeCategory, openAirways)

    return (
     <>
      <Pane name="report-points--pane" style={{ zIndex: 1500 }}>
        {reportPoints.map((reportPoint) => {
          return (
            <Circle
              key={reportPoint.icao}
              title={reportPoint.name}
              icao={reportPoint.icao}
              pathOptions={pathOptions} 
              radius={800}
              center={reportPoint.latlng}
              eventHandlers={{
                click: (e) => {
                  addToRoute(e.target.getLatLng(),e.target.options.title,e.target.options.icao)
                },
              }}>
            </Circle>
          ) 
        })}
      </Pane>
     </>
    );
  }

export default memo(ReportPoints);
