// import {calcDbPointsDistance, getTimeCategoryHour, isInTimeCategory} from ".";
import {isInTimeCategory, filterAirstrips, filterBubbles} from ".";
import {lsaConstantReportPoints, 
        lsaNegevReportPoints,  
        lsaWeekendReportPoints, 
        cvfrReportPoints, 
        bubblesPoints, 
        bubbles, 
        airstrips 
    } from "../data";

/***********************************************/

export function dbPointToRoutePoint(dbPoint){
    return( {
        "latlng": { 
            lat: dbPoint.latlng[0],
            lng: dbPoint.latlng[1]
        },
        "name"  : dbPoint.name,
        "icao"  : dbPoint.icao.slice(0, 5),
        "key"   : Math.random() * 1000
    })
}

/***********************************************/

export function isAirport (icao) 
{
    return (icao.slice(0, 2) === "LL")
}

/***********************************************/

const isAirwayOpened = (timeCategory,airwayTime) => {

    return  isInTimeCategory(timeCategory,airwayTime)
}    

/***********************************************/

export function LoadReportPoints(mapUsed, timeCategory, openAirways = null){

    var reportPoints = [];

    if(mapUsed === "cvfr"){
        reportPoints = cvfrReportPoints
    } else {
        if(timeCategory === "WeekDay"){
            reportPoints = lsaConstantReportPoints;
        } else if (timeCategory === "OnlyWeekendPoints"){
            reportPoints = [...lsaWeekendReportPoints,...lsaNegevReportPoints];
        } else {
            reportPoints = [...lsaWeekendReportPoints,...lsaNegevReportPoints,...lsaConstantReportPoints];
        }
    }

    const openedPoints = reportPoints.filter((point) => {
        if(openAirways){
            if (mapUsed === "cvfr")
                return (openAirways.cvfrOpenAirways.reportPoints.includes(point.icao))
            else {
                return (openAirways.lsaOpenAirways.reportPoints.includes(point.icao))
            }
        } else {
            return true
        }
    })

    return openedPoints
}

/***********************************************/

export function  LoadAirstrips(mapUsed,rules,openAirways = null){


    const openedAirstrips = airstrips.filter((airstrip) => {
        if(openAirways){
            if (mapUsed === "cvfr")
                return (openAirways.cvfrOpenAirways.airstrips.includes(airstrip.icao))
            else {
                return (openAirways.lsaOpenAirways.airstrips.includes(airstrip.icao))
            }
        } else {
            return true
        }
    })

    return filterAirstrips (openedAirstrips, rules)
}

/***********************************************/

export function LoadBubblesPoints(mapUsed,timeCategory, rules, openAirways = null){

    var bubblesArray = bubblesPoints.filter((bubble) => {

        if (mapUsed === "cvfr")
            if(openAirways){
                return (openAirways.cvfrOpenAirways.bubbles.includes( bubble.icao.slice(0, 5)))
            }else {
                return false;
            }
        else {
            if( openAirways){
                if (!openAirways.lsaOpenAirways.bubbles.includes( bubble.icao.slice(0, 5)))
                    return false;
            }
        }

        return isAirwayOpened(timeCategory, bubble.timeOpened) && !isBubblePointClosed ( bubble.icao.slice(0, 5), rules)
    })

    return bubblesArray;

    function isBubblePointClosed (icao, rules) {

        if(rules){
            for (const rule of rules){
                if( rule.bubbles && rule.bubbles.includes(icao) &&
                 (rule.airwayType === "LSA-BUBBLES" || rule.airwayType === "POLYGON"  || rule.airwayType === "CIRCLE") && rule.status === "CLOSED" ) {
                    return true;
                }
            }
        }
        return false;
    }
}

/***********************************************/

export function LoadBubbles(mapUsed,timeCategory, rules, openAirways = null){


    var openedBubbles = bubbles.filter((bubble) => {

        if (mapUsed === "cvfr")
            if(openAirways){
                return (openAirways.cvfrOpenAirways.bubbles.includes(bubble.icao))
            }else {
                return false;
            }
        else {
            if( openAirways){
                if (!openAirways.lsaOpenAirways.bubbles.includes(bubble.icao))
                    return false;
            }
        }

        return isAirwayOpened(timeCategory, bubble.timeOpened)
    })

    return filterBubbles(openedBubbles,rules)
}

/***********************************************/

