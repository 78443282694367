import {memo, useState} from "react";
import { Polyline, Polygon ,Pane, Circle, useMap} from 'react-leaflet';
import {LoadReportPoints, LoadBubbles,LoadAirstrips,isProduction} from "../utils";
import {NotamNotification} from ".";
import GeoUtil from "leaflet-geometryutil";
import gju from "geojson-utils";


export function ClosedAirways(props) {

  const {mapUsed,timeCategory, rules} = props

  const [notamNotificationOpen , setNotamNotificationOpen] = useState(false)
  const [closeDescription , setCloseDescription] = useState("")
  const [originalNotam , setOriginalNotam] = useState(null)
  const flightMap = useMap()

  console.log('Loading ClosedAirways');

  var routeRules = []
  var bubbleRules = []
  var areaRules = []
  var circleRules = []

  for (const rule of rules){
    if(rule.status === "CLOSED" ) {
        if((rule.airwayType === "LSA-ROUTE" && mapUsed === "lsa") || (rule.airwayType === "CVFR-ROUTE" && mapUsed === "cvfr")){
          routeRules.push(rule)
        } else if((rule.airwayType === "LSA-BUBBLES" && mapUsed === "lsa")){
          bubbleRules.push(rule)
        }  else if((rule.airwayType === "POLYGON")){
          areaRules.push(rule)
        }  else if((rule.airwayType === "CIRCLE")){
          circleRules.push(rule)
        }
    }
  }

  const shortenRoute = (route ,meters) => {
    const rLength = route.length

    if(rLength > 1){

      var lat1 = route[0][0]
      var lng1 = route[0][1]
      var lat2 = route[1][0]
      var lng2 = route[1][1]

      var segmentLen =gju.pointDistance({type: 'Point', coordinates:[lng1, lat1]},
                                        {type: 'Point', coordinates:[lng2, lat2]})
      if(segmentLen > meters + 1000){

        const startPoint = GeoUtil.destinationOnSegment(flightMap,{lat: lat1, lng: lng1},{lat: lat2, lng: lng2},meters)

        if(startPoint){
          route[0] = [startPoint.lat,startPoint.lng];
        }
      }

      lat1 = route[rLength - 1][0]
      lng1 = route[rLength - 1][1]
      lat2 = route[rLength - 2][0]
      lng2 = route[rLength - 2][1]

      segmentLen =gju.pointDistance({type: 'Point', coordinates:[lng1, lat1]},
                                    {type: 'Point', coordinates:[lng2, lat2]})

      if(segmentLen > meters + 1000){
        const endPoint = GeoUtil.destinationOnSegment(flightMap,{lat: lat1, lng: lng1},{lat: lat2, lng: lng2},meters)

        if(endPoint){
          route[rLength - 1] = [endPoint.lat,endPoint.lng];
        }
      }
    } 

    return route;
  }

  var closedRoutes = []

  if(routeRules.length){

    const reportPoints = [...LoadReportPoints(mapUsed,timeCategory),...LoadAirstrips(mapUsed,[],null)]

    for (const rule of routeRules){
      if(rule.routes && rule.routes.length){

        for(const route of rule.routes){

          var closedRoute = {
            coords : [],
            route: route,
            closeDescription:  "הנתיב סגור " + rule.timeReason,
            originalNotam: null,
          }

          if(rule.ident){
            closedRoute.closeDescription +=  ` ראה נוטאם מספר ${rule.ident}\n`;  
            if (!isProduction()) {closedRoute.originalNotam = rule};
          }
          
          for(const routePoint of route){
            const ind = reportPoints.findIndex((o) => o.icao === routePoint);
            if (ind > -1) {
              closedRoute.coords.push(reportPoints[ind].latlng)
            }
          }
          
          if(closedRoute.coords.length === route.length){
            closedRoute.coords = shortenRoute(closedRoute.coords,1500)
            closedRoutes.push(closedRoute)
          }
        }
      }
    }
  }

  var closedBubbles = []

  if(bubbleRules.length){

    const bubblesData = LoadBubbles(mapUsed, timeCategory, rules)

    closedBubbles = bubblesData.filter((bubble) => {
      return (bubble.closed)
    })
  }

  var closedAreas = areaRules.map((rule) => {

    const closedArea = {
      coords :rule.polygon,
      closeDescription:  "אזור סגור " + rule.timeReason,
      originalNotam: null,
    }

    if(rule.lowerAlt)         {closedArea.closeDescription +=  " מגובה " + rule.lowerAlt}
    if(rule.upperAlt < 90000) {closedArea.closeDescription +=  " עד גובה " + rule.upperAlt + '.'}
    if(rule.ident)            {closedArea.closeDescription +=  ` ראה נוטאם מספר ${rule.ident}`}
    if (! isProduction())     {closedArea.originalNotam = rule};

    return closedArea;
  })


  var closedCircles = circleRules.map((rule) => {

    const closedCircle = {
      center: rule.center,
      radius: rule.radius,
      // radius: rule.radius * 1850,
      closeDescription:  "אזור סגור " + rule.timeReason,
      originalNotam: null,
    }

    if (rule.lowerAlt)        {closedCircle.closeDescription +=  " מגובה " + rule.lowerAlt}
    if (rule.upperAlt < 90000){closedCircle.closeDescription +=  " עד גובה " + rule.upperAlt + '.'}
    if(rule.ident)            {closedCircle.closeDescription +=  ` ראה נוטאם מספר ${rule.ident}`}
    if (! isProduction())     {closedCircle.originalNotam = rule};


    return closedCircle;
  })
  
  const airwayClicked = (latLng, airway) => {
    setCloseDescription(airway.closeDescription)
    setOriginalNotam(airway.originalNotam)
    setNotamNotificationOpen(true)
  }

  const bubbleOptions = { color: 'red', opacity: 0.5, fillOpacity: 0.2 }
  const routeOptions = { color: 'red', opacity: 1, fillOpacity: 0.1, weight: 10 }
  const areaOptions = { color: 'red', opacity: 0.5, fillOpacity: 0.2 }

  if(closedRoutes.length || closedBubbles.length || closedAreas.length || closedCircles.length) {

    return (
    <>
      <Pane name="closed-areas" style={{ zIndex: 20000 }}>

        {closedBubbles.map((bubble) => {
          return(
            <Polygon 
              pathOptions={bubbleOptions} 
              positions={bubble.coords}
              key={Math.random() * 1000 }
              title={bubble.name}
              icao={bubble.icao}
              zIndexOffset = {-500}
              closed = {bubble.closed}
              closeDescription = {bubble.closeDescription}
              originalNotam = {bubble.originalNotam}
              eventHandlers={{
                click: (e) => {airwayClicked(e.latlng, e.target.options)},
              }}>
            </Polygon>
          )
        })}

        {closedRoutes.map((route) => {
          return(
            <Polyline 
              pathOptions={routeOptions} 
              eventHandlers={{
                mousedown: (e) => {airwayClicked (e.latlng, e.target.options)},
                touchstart: (e) => {airwayClicked(e.latlng, e.target.options)},
              }}
              closeDescription = {route.closeDescription}
              originalNotam = {route.originalNotam}
              key = {Math.random() * 1000}
              positions={route.coords} 
              bubblingMouseEvents = {true}
              zIndexOffset = {500}
            />
          )
        })}

        {closedAreas.map((area) => {
          return(
            <Polygon 
              pathOptions={areaOptions} 
              positions={area.coords}
              key={Math.random() * 1000 }
              zIndexOffset = {500}
              closed = {true}
              closeDescription = {area.closeDescription}
              originalNotam = {area.originalNotam}
              eventHandlers={{
                click: (e) => {airwayClicked(e.latlng, e.target.options)},
              }}>
            </Polygon>
          )
        })}

        {closedCircles.map((circle) => {
          return(
            <Circle
              pathOptions={areaOptions} 
              key={Math.random() * 1000 }
              zIndexOffset = {-500}
              closed = {true}
              radius={circle.radius}
              center={circle.center}
              closeDescription = {circle.closeDescription}
              originalNotam = {circle.originalNotam}
              eventHandlers={{
                click: (e) => {airwayClicked(e.latlng, e.target.options)},
              }}>
            </Circle>
          )
        })}

      </Pane>

      <NotamNotification  
        notamNotificationOpen = {notamNotificationOpen}  
        setNotamNotificationOpen = {setNotamNotificationOpen}
        description = {closeDescription} 
        originalNotam = {originalNotam} 
      />

    </>
    )

  } else {
    return null
  }
}

export default memo(ClosedAirways);
