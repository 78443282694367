import {FindNearestFeature, CalcBestRoute} from ".";


export function LoadUrlParams (routeStr,setPlanedRoute, resetRoute, flightMap, mapToUse , timeCategory,rules, openAirways) {


    var maxLat = -180;
    var minLat = 180;
    var maxLng = -180;
    var minLng = 180
    var newRoute = [];

    const LineCoords = routeStr.split("-");

    // var routeChanged = false

    if(LineCoords.length){
        LineCoords.forEach(point => {
            handlePoint(point)
        });

        if(newRoute.length){
            resetRoute()
            CalcBestRoute(mapToUse, timeCategory, newRoute, setPlanedRoute,rules,openAirways, RouteChanged, msgDummy,false);
            // if(routeChanged){
            //     alert(`שים לב: הנתיב שונה כדי לעמוד בחוקי הפמ"ת`)
            // }
        }
    }

    const bounds = [[minLat,minLng],[maxLat,maxLng]]
    flightMap.fitBounds(bounds)

    /********************************/

    function RouteChanged(changed){
        // routeChanged = changed
    }

    function msgDummy(msg){}

    function handlePoint(point){
        if(point.length === 15 && point.indexOf(':') === 7){
            const latlng = {
                lat: parseFloat(point.slice(0,7)),
                lng: parseFloat(point.slice(8))
            }

            // Calculate the bounds
            maxLat = latlng.lat > maxLat ? latlng.lat : maxLat;
            minLat = latlng.lat < minLat ? latlng.lat : minLat;
            maxLng = latlng.lng > maxLng ? latlng.lng : maxLng;
            minLng = latlng.lng < minLng ? latlng.lng : minLng;

            const newPoint = {
                latlng: latlng,
                name: "none",
                icao: "LLLL",
                key: Math.random() * 1000
            }

            const updatedPoint = FindNearestFeature(newPoint,mapToUse, timeCategory,rules, openAirways)

            if(updatedPoint.name !== "none"){
                newRoute.push(updatedPoint)
            }
            // else {
            //     routeChanged = true;
            // }
        }
    }
}

export default LoadUrlParams;