import { flightTimeValues } from "../data";
import {getLocalStorageKey, CalcBestRoute} from ".";


export function CreateRouteBack(mapUsed, planedRoute, setPlanedRoute,setAlertDialogOpen, setAlertDialogMessage, updateDepartureTime, departureTime, filteredRules, openAirways ){

    var fplSavedContent = getLocalStorageKey("fplSavedContent")

    if(fplSavedContent){
        var flightTimeText = fplSavedContent.flightTime
        if(flightTimeText){

            const index = flightTimeValues.findIndex((a) => a[0] === flightTimeText);

            if (index > -1) {
                departureTime.setMinutes(departureTime.getMinutes() + flightTimeValues[index][2])
            } else {
                var flightTime = parseInt(flightTimeText) / 100
                departureTime.setHours(departureTime.getHours() + parseInt(flightTime) / 100)
            }
        }
    }

    var timeCategory =   updateDepartureTime(departureTime)

    if(planedRoute.length > 1){
        var fromTo = [planedRoute[planedRoute.length - 1],planedRoute[0]]
        CalcBestRoute(mapUsed,timeCategory, fromTo, setPlanedRoute, filteredRules, openAirways,setAlertDialogOpen, setAlertDialogMessage,false)
    }

}