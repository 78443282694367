import {Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, } from '@mui/material';
import {ThumbUpIcon} from '../assets';


export function SentSuccessDialog (props) {

    const { sentDialogOpen , setSentDialogOpen } = props

    /***********************************************/
    
        const closeDialog = ()=>{ 
            setSentDialogOpen(false)
        }
   
    /***********************************************/
        
        return(
        
            
            <Dialog className="fpl-submit" open={sentDialogOpen}>
            <DialogTitle>תוכנית הטיסה נשלחה בהצלחה</DialogTitle>
            <DialogContent >
    
                <DialogContentText>
                    התוכנית תיכנס לתוקף רק לאחר קבלת אישור באימייל ממודיעין טייס  
                </DialogContentText>      
                <p/><p/>
              

                <DialogContentText style={{color: 'red', fontWeight: 'bold'}}>
                    אין להמריא ללא קבלת האישור!
                </DialogContentText>

                {/* {backMessage && 
                <p>
                    אל תשכח להכין תוכנית טיסה חזרה.
                </p>
                } */}

                <div style={{textAlign: 'center'}}>
                    <p/><p/>
                    <ThumbUpIcon/>
                </div>

              
            </DialogContent>
    
            <DialogActions>
                <Button variant="contained" onClick={closeDialog}>סיום</Button> 
            </DialogActions>
          </Dialog>
        )    

    }

    export default SentSuccessDialog;
   
